import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import Api from "../../helper/Api";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useStore} from "../../store/useStore";
import {helperCatchErrors, inputOnlyFloat, selectIconComponent} from "../../helper/Helper";
import {Box, Grid, Paper, Select} from "@material-ui/core";
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";
import pages from "../../config/pages";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

const AssessmentNormgroupsForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [mounted, setMounted] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [procedureObject, setProcedureObject] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [loadingData, setLoadingData] = React.useState(true);
    const [loadingNormgroups, setLoadingNormgroups] = React.useState(true);
    const [loadingDimensions, setLoadingDimensions] = React.useState(true);
    const [loadingProcedure, setLoadingProcedure] = React.useState(true);

    const [normgroups, setNormgroups] = React.useState([]);
    const [dimensions, setDimensions] = React.useState([]);

    const [selectedDimension, setSelectedDimension] = React.useState('');
    const [normgroupValues, setNormgroupValues] = React.useState((state.editForm.normgroupValues) ? state.editForm.normgroupValues : []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }, []);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const showSuccess = useCallback(() => {
        window.scrollTo(0, 0);
        setNotificationError(false);
        setNotificationSuccess(true);

        setTimeoutWatcher(setTimeout(() => {
            dispatch({type: "setPage", payload: pages.ASSESSMENT_CREATE});
        }, 3000));
    }, [dispatch]);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!loadingNormgroups && !loadingDimensions) {
            setLoadingData(false);
        }
    }, [loadingDimensions, loadingNormgroups]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setNormgroups(res.data['hydra:member']);
                } else {
                    showError(GlobalTransIntl('error_no_dimension', intl));
                }

                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, intl, showError, state.token]);

    const getDimensions = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isHidden',
                value: false,
            }
        ]);

        Api.getApi(
            'dimensions' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    const filteredDimensions = res.data['hydra:member'].filter(dimension => Object.keys(procedureObject.dimensionTemplates).indexOf(dimension.id.toString()) !== -1);
                    setDimensions(filteredDimensions);

                    if (filteredDimensions.length) {
                        setSelectedDimension(filteredDimensions[0]['@id']);
                    }
                } else {
                    showError(GlobalTransIntl('error_no_dimension', intl));
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, intl, showError, state.token, procedureObject.dimensionTemplates]);

    const updateProcedure = useCallback(() => {
        const data = {name: procedureObject.name};

        axios.patch(config.apiUrl + `/procedures/${procedureObject.id}`, data, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'application/merge-patch+json'}
        }))
            .then(res => {
                if (res.data && res.data['@id']) {
                    setProcedureObject(res.data);
                    setLoadingProcedure(false);
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, procedureObject.id, procedureObject.name, showError, state.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (!procedureObject) {
                showError(GlobalTransIntl('error_no_assessment_id', intl));
            } else {
                dispatch({type: "resetEditForm"});

                updateProcedure();
            }
        }
    }, [dispatch, intl, mounted, procedureObject, showError, updateProcedure]);

    useEffect(() => {
        if (!loadingProcedure) {
            getDimensions();
            getNormgroups();
        }
    }, [loadingProcedure, getDimensions, getNormgroups]);

    const parseFloatAllValues = useCallback(() => {
        normgroupValues.forEach((element) => {
            element['average'] = parseFloat(element['average']);
            element['standardDeviation'] = parseFloat(element['standardDeviation']);
        });
    }, [normgroupValues]);

    const calculateAvailableNormgroups = useCallback(() => {
        const availableNormgroups = [];

        normgroups.forEach(normgroup => {
            const foundNormgroupValues = normgroupValues.filter((item) => item.normgroup === normgroup['@id']);

            if (foundNormgroupValues.length === dimensions.length) {
                availableNormgroups.push(normgroup['@id']);
            }
        });

        return availableNormgroups;
    }, [dimensions, normgroupValues, normgroups]);

    const submitNormgroupValues = useCallback(() => {
        resetNotifications();

        procedureObject.normgroupValues = normgroupValues;
        procedureObject.availableNormgroups = calculateAvailableNormgroups();

        if (procedureObject.reportScala) {
            procedureObject.reportScala = procedureObject.reportScala['@id'];
        }

        parseFloatAllValues();

        axios.put(config.apiUrl + `/procedures/${procedureObject.id}`, procedureObject, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/ld+json'}}))
            .then(res => {
                if (res.data && res.data['@id']) {
                    showSuccess();

                    dispatch({type: "setEditForm", payload: procedureObject});
                    dispatch({type: "setPage", payload: pages.ASSESSMENT_DIMENSION_TEMPLATES});
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }, [procedureObject, cancelToken.token, catchErrors, showError, showSuccess, state.token, normgroupValues, parseFloatAllValues, dispatch, calculateAvailableNormgroups]);

    const setNormgroupValueByNormgroup = useCallback((normgroupId, valueType, value) => {
        const existingValueIndex = normgroupValues.findIndex(
            item =>
                item.normgroup === normgroupId &&
                item.dimension === selectedDimension
        );

        if (existingValueIndex !== -1) {
            setNormgroupValues(prev => {
                const newNormgroupValues = [...prev];

                newNormgroupValues[existingValueIndex][valueType] = value;

                return newNormgroupValues;
            });
        } else {
            setNormgroupValues(prev => (
                [
                    ...prev,
                    {
                        normgroup: normgroupId,
                        dimension: selectedDimension,
                        [valueType]: value
                    }
                ]
            ));
        }
    }, [selectedDimension, normgroupValues]);

    const getNormgroupValueByNormgroup = useCallback((normgroupId, value) => {
        const findNormgroupValue = normgroupValues.find(
            (item) =>
                item.normgroup === normgroupId &&
                item.dimension === selectedDimension
        );

        if (findNormgroupValue) {
            return findNormgroupValue[value] || '';
        }

        return  '';
    }, [normgroupValues, selectedDimension]);

    return (
        <Paper>
            <Spinner show={loadingDimensions} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            {
                !loadingData && (dimensions.length > 0) && (normgroups.length > 0) &&
                <Box p={2}>
                    <Box mb={2}>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} lg={6} xl={4}>
                                <FormControl variant={'outlined'} id={'assessments-normgroups-dimension'}>
                                    <InputLabel>
                                        {GlobalTransIntl('dimension', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="assessments--normgroups-dimension-select"
                                        value={selectedDimension}
                                        onChange={(event) => {
                                            setSelectedDimension(event.target.value);
                                        }}
                                        label={GlobalTransIntl('dimension', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            dimensions.map((dimension) => (
                                                <MenuItem
                                                    value={dimension['@id']}
                                                    key={dimension['@id']}
                                                >
                                                    {dimension.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} lg={2}>{GlobalTransIntl('normgroup', intl)}</Grid>
                                <Grid item xs={4} lg={5}>{GlobalTransIntl('average', intl)}</Grid>
                                <Grid item xs={4} lg={5}>{GlobalTransIntl('standard_deviation', intl)}</Grid>
                            </Grid>
                        </Grid>
                        {
                            normgroups.map((normgroup) => (
                                <Grid item xs={12} key={normgroup['@id']}>
                                    <Grid container spacing={2} className={'align-items-center'}>
                                        <Grid item xs={4} lg={2}>
                                            {normgroup.name}
                                        </Grid>
                                        <Grid item xs={4} lg={5}>
                                            <TextField label={GlobalTransIntl('average', intl)}
                                                       id={'project-create--average'}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {
                                                           inputOnlyFloat(e, () => setNormgroupValueByNormgroup(normgroup['@id'], 'average', e.target.value))

                                                       }}
                                                       value={getNormgroupValueByNormgroup(normgroup['@id'], 'average')}
                                            />
                                        </Grid>
                                        <Grid item xs={4} lg={5}>
                                            <TextField label={GlobalTransIntl('standard_deviation', intl)}
                                                       id={'project-create--standard-deviation'}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {
                                                           inputOnlyFloat(e, () => setNormgroupValueByNormgroup(normgroup['@id'], 'standardDeviation', e.target.value))
                                                       }}
                                                       value={getNormgroupValueByNormgroup(normgroup['@id'], 'standardDeviation')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Box mt={2}>
                        <Button variant="primary" type="submit" onClick={submitNormgroupValues}>
                            {GlobalTransIntl('button_save', intl)}
                        </Button>
                    </Box>
                </Box>
            }
        </Paper>
    );
};

export default AssessmentNormgroupsForm;